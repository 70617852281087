<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Supervisor</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  item-value="extEmployeeId"
                  item-text="extEmployeeName"
                  :items="listEmployee"
                  v-model="form.supervisor"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-data-table
              :headers="headerReff"
              :items="form.references"
              disable-pagination
              hide-default-footer
              @click:row="onClickRowReff"
            >
              <template v-slot:top>
                <v-btn color="success" @click="addReference">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <p class="headline font-italic text-right">References</p>
              </template>
              <template v-slot:item.no="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.action="{ index }">
                <v-btn color="error" @click.stop="deleteReference(index)">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-data-table
              :headers="headerTask"
              :items="form.tasks"
              disable-pagination
              hide-default-footer
              @click:row="onClickRowTask"
            >
              <template v-slot:top>
                <v-btn color="success" @click="addTask">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  item-text="name"
                  :items="listTaskTemplate"
                  v-model="taskTemplate"
                  return-object
                  @change="onChangeTemplate"
                ></v-autocomplete>
                <v-spacer></v-spacer>
                <p class="headline font-italic text-right">Tasks<span class="red--text">*</span></p>
              </template>
              <template v-slot:item.no="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.peopleInCharge="{ item }">
                {{ item.peopleInCharge.map(x => x.extEmployeeName).toString() }}
              </template>
              <template v-slot:item.action="{ index }">
                <v-btn color="error" @click.stop="deleteTask(index)">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-btn color="primary" @click="submit">
              <v-icon large>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-task
      :item="item"
      :listCatalog="listCatalog"
      :listEmployee="listEmployee"
      :dialog="dialog"
      @close="close"
      @save="saveTask"
      type="wo"
    ></dialog-task>
    <dialog-reference
      :dialog="dialogReff"
      :item="item"
      :listInvoiceVendor="listInvoiceVendor"
      :listInvoiceCustomer="listInvoiceCustomer"
      :listPaymentVendor="listPaymentVendor"
      :listPaymentCustomer="listPaymentCustomer"
      :listGeneralKasbon="listGeneralKasbon"
      :listDriverKasbon="listDriverKasbon"
      :listInstallerKasbon="listInstallerKasbon"
      @save="saveReference"
      @close="close"
    ></dialog-reference>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import DialogTask from "@/components/DialogTask";
import DialogReference from "@/components/DialogReference";
import moment from "moment";

export default {
  name: "create-wo",
  components: {
    DialogTask,
    DialogReference,
  },
  data: () => ({
    defaultForm: {
      appSource: "ACCOUNTING",
      supervisor: null,
      references: [],
      tasks: [],
    },
    form: {},
    dialog: false,
    dialogReff: false,
    item: {},
    valid: true,
    taskTemplate: {},
    headerReff: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Type",
        value: "type",
        sortable: false,
        divider: true,
      },
      {
        text: "Reference",
        value: "referenceId",
        sortable: false,
        divider: true,
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        divider: true,
      },
    ],
    headerTask: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Description",
        value: "description",
        sortable: false,
        divider: true,
      },
      {
        text: "Catalog",
        value: "subProcessId",
        sortable: false,
        divider: true,
      },
      {
        text: "PIC",
        value: "peopleInCharge",
        sortable: false,
        divider: true,
      },
      {
        text: "Date",
        value: "date",
        sortable: false,
        divider: true,
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        divider: true,
      },
    ],
  }),

  computed: {
    ...mapState("wo", [
      "listCatalog",
      "listInvoiceVendor",
      "listInvoiceCustomer",
      "listPaymentVendor",
      "listPaymentCustomer",
      "listGeneralKasbon",
      "listDriverKasbon",
      "listInstallerKasbon",
      "listEmployee",
    ]),
    ...mapState("taskTemplate", ["listTaskTemplate"]),
  },

  methods: {
    async submit() {
      if (this.$refs.form.validate() && this.form.tasks.length > 0) {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("wo/create", this.form)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "All is required",
        });
      }
    },
    deleteTask(index) {
      if (index > -1) {
        this.form.tasks.splice(index, 1);
      }
    },
    deleteReference(index) {
      if (index > -1) {
        this.form.references.splice(index, 1);
      }
    },
    addTask() {
      this.item = {
        description: "",
        subProcessId: null,
        date: moment().format("yyyy-MM-DD"),
        peopleInCharge: [],
      };
      this.dialog = true;
    },
    addReference() {
      this.item = {
        type: null,
        referenceId: null,
      };
      this.dialogReff = true;
    },
    saveTask(item) {
      const index = this.form.tasks.findIndex(x => x === item);
      if (index > -1) {
        this.form.tasks[index] = item;
      } else {
        this.form.tasks.push(item);
      }
    },
    saveReference(item) {
      const index = this.form.references.findIndex(x => x === item);
      if (index > -1) {
        this.form.references[index] = item;
      } else {
        this.form.references.push(item);
      }
    },
    close() {
      this.dialog = false;
      this.dialogReff = false;
    },
    onClickRowReff(item) {
      this.item = item;
      this.dialogReff = true;
    },
    onClickRowTask(item) {
      this.item = item;
      this.dialog = true;
    },
    onChangeTemplate(val) {
      val.items.forEach(x => {
        this.form.tasks.push({
          type: x.subProcessId === null ? "manual" : "catalog",
          description: x.description,
          subProcessId: x.subProcessId,
          date: moment().format("yyyy-MM-DD"),
          peopleInCharge: [],
        });
      });
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        await this.$store.dispatch("wo/getCatalog"),
        await this.$store.dispatch("wo/getInvoiceVendor"),
        await this.$store.dispatch("wo/getInvoiceCustomer"),
        await this.$store.dispatch("wo/getPaymentVendor"),
        await this.$store.dispatch("wo/getPaymentCustomer"),
        await this.$store.dispatch("wo/getGeneralKasbon"),
        await this.$store.dispatch("wo/getDriverKasbon"),
        await this.$store.dispatch("wo/getInstallerKasbon"),
        await this.$store.dispatch("wo/getEmployee"),
        await this.$store.dispatch("taskTemplate/getAll"),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },

  mounted() {
    this.getInitialData();
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
