<template>
  <v-dialog v-model="dialog" width="900" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Task</span>
      </v-card-title>
      <v-card-text>
        <v-form lazy-validation v-model="valid" ref="form">
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-row>
                <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                  <v-subheader>Type<span class="red--text">*</span></v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                  <v-autocomplete
                    flat
                    dense
                    outlined
                    background-color="white"
                    :items="listType"
                    v-model="item.type"
                    :rules="[v => !!v || 'Type is required']"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-row>
                <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                  <v-subheader>Reference<span class="red--text">*</span></v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                  <v-autocomplete
                    flat
                    dense
                    outlined
                    background-color="white"
                    :items="listInvoiceVendor"
                    v-model="item.referenceId"
                    v-if="item.type === 'INVOICE_VENDOR'"
                    :rules="[v => !!v || 'Reference is required']"
                  ></v-autocomplete>
                  <v-autocomplete
                    flat
                    dense
                    outlined
                    background-color="white"
                    :items="listInvoiceCustomer"
                    v-model="item.referenceId"
                    v-if="item.type === 'INVOICE_CUSTOMER'"
                    :rules="[v => !!v || 'Reference is required']"
                  ></v-autocomplete>
                  <v-autocomplete
                    flat
                    dense
                    outlined
                    background-color="white"
                    :items="listPaymentVendor"
                    v-model="item.referenceId"
                    v-if="item.type === 'PAYMENT_VENDOR'"
                    :rules="[v => !!v || 'Reference is required']"
                  ></v-autocomplete>
                  <v-autocomplete
                    flat
                    dense
                    outlined
                    background-color="white"
                    :items="listPaymentCustomer"
                    v-model="item.referenceId"
                    v-if="item.type === 'PAYMENT_CUSTOMER'"
                    :rules="[v => !!v || 'Reference is required']"
                  ></v-autocomplete>
                  <v-autocomplete
                    flat
                    dense
                    outlined
                    background-color="white"
                    :items="listGeneralKasbon"
                    v-model="item.referenceId"
                    v-if="item.type === 'GENERAL_KASBON'"
                    :rules="[v => !!v || 'Reference is required']"
                  ></v-autocomplete>
                  <v-autocomplete
                    flat
                    dense
                    outlined
                    background-color="white"
                    :items="listDriverKasbon"
                    v-model="item.referenceId"
                    v-if="item.type === 'DRIVER_KASBON'"
                    :rules="[v => !!v || 'Reference is required']"
                  ></v-autocomplete>
                  <v-autocomplete
                    flat
                    dense
                    outlined
                    background-color="white"
                    :items="listInstallerKasbon"
                    v-model="item.referenceId"
                    v-if="item.type === 'INSTALLER_KASBON'"
                    :rules="[v => !!v || 'Reference is required']"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">
          Close
        </v-btn>
        <v-btn color="primary" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-reference",
  props: {
    dialog: Boolean,
    item: Object,
    listInvoiceVendor: Array,
    listInvoiceCustomer: Array,
    listPaymentVendor: Array,
    listPaymentCustomer: Array,
    listGeneralKasbon: Array,
    listDriverKasbon: Array,
    listInstallerKasbon: Array,
  },
  data() {
    return {
      listType: [
        "INVOICE_CUSTOMER",
        "INVOICE_VENDOR",
        "PAYMENT_CUSTOMER",
        "PAYMENT_VENDOR",
        "GENERAL_KASBON",
        "DRIVER_KASBON",
        "INSTALLER_KASBON",
      ],
      valid: true,
    };
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.$emit("save", this.item);
        this.$emit("close");
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
