<template>
  <v-dialog v-model="dialog" width="900" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Task</span>
      </v-card-title>
      <v-card-text>
        <v-form lazy-validation v-model="valid" ref="form">
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-row>
                <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                  <v-subheader>Type<span class="red--text">*</span></v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                  <v-select
                    flat
                    dense
                    outlined
                    background-color="white"
                    :items="listType"
                    v-model="item.type"
                    :rules="[v => !!v || 'Type is required']"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" v-if="item.type === 'catalog'">
              <v-row>
                <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                  <v-subheader>Catalog<span class="red--text">*</span></v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                  <v-autocomplete
                    flat
                    dense
                    outlined
                    background-color="white"
                    :items="listCatalog"
                    v-model="item.subProcessId"
                    return-object
                    item-text="name"
                    @change="onChangeCatalog"
                    :rules="[v => !!v || 'Catalog is required']"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6" v-if="type === 'wo'">
              <v-row>
                <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                  <v-subheader>PIC<span class="red--text">*</span></v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                  <v-autocomplete
                    flat
                    dense
                    outlined
                    background-color="white"
                    :items="listEmployee"
                    v-model="item.peopleInCharge"
                    item-text="extEmployeeName"
                    return-object
                    multiple
                    :rules="[v => !!v || 'PIC is required']"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" v-if="type === 'wo'">
              <v-row>
                <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                  <v-subheader>Date<span class="red--text">*</span></v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                  <v-menu
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        flat
                        dense
                        background-color="white"
                        v-model="item.date"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[v => !!v || 'Date is required']"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="item.date" @input="menuDate = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-row>
                <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                  <v-subheader>Description<span class="red--text">*</span></v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="10" lg="10" class="py-0">
                  <v-textarea
                    outlined
                    flat
                    rows="3"
                    background-color="white"
                    v-model="item.description"
                    :disabled="item.type === 'catalog'"
                    :rules="[v => !!v || 'Description is required']"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">
          Close
        </v-btn>
        <v-btn color="primary" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-kasbon",
  props: {
    dialog: Boolean,
    item: Object,
    listCatalog: Array,
    listEmployee: Array,
    type: String,
  },
  data() {
    return {
      listType: ["catalog", "manual"],
      menuDate: false,
      valid: true,
    };
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.$emit("save", this.item);
        this.$emit("close");
      }
    },
    close() {
      this.$emit("close");
    },
    onChangeCatalog(val) {
      this.item.description = val.name;
      this.item.subProcessId = val.masterJavaBaseModel.id;
    },
  },
};
</script>

<style></style>
